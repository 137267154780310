<template>
  <div class="min-h-screen bg-white flex">
    <div class="flex-1 flex flex-col justify-center
                py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <img class="h-9 w-auto mx-auto" src="../assets/log.png" alt="Workflow" />

        </div>

        <div class="mt-8">
          <div>
            <div>
              <p class="text-sm font-medium text-gray-700">
                Sign in via magic link with your email below
              </p>
            </div>
          </div>

          <div class="mt-6">
            <form @submit.prevent="handleLogin" class="space-y-6">
              <div>
                <label for="email" class="block text-sm font-medium text-gray-700">
                  <div class="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autocomplete="email"
                      placeholder="Email address"
                      required=""
                      v-model="email"
                      class="appearance-none block w-full px-3 py-2 border border-gray-300
                            rounded-md shadow-sm placeholder-gray-400 focus:outline-none
                            focus:ring-zinc-500 focus:border-zinc-500 sm:text-sm" />
                  </div>
                </label>
              </div>

              <div>
                <button type="submit"
                  class="button-submit"
                  :value="buttonText" :disabled="loading">
                  {{ buttonText }}
                </button>

              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden lg:block relative w-0 flex-1">
      <img class="absolute inset-0 h-full w-full object-cover" src="../assets/moe2.jpg" alt="" />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import supabase from '@/lib/supabaseClient';

const loading = ref(false);
const buttonText = ref('Send link');
const email = ref('');

const handleLogin = async () => {
  try {
    loading.value = true;
    buttonText.value = 'Sending...';
    const { error } = await supabase.auth.signInWithOtp({
      email: email.value,
    });
    if (error) {
      buttonText.value = 'Error...';
    } else {
      buttonText.value = 'Check mailbox';
    }
  } finally {
    loading.value = true;
  }
};

</script>

<style scope>
.button-submit {
  @apply
  w-full flex justify-center py-2 px-4 border border-transparent rounded-md
  shadow-sm text-sm font-medium text-white bg-zinc-900 hover:bg-zinc-700
  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-zinc-500
}
</style>
