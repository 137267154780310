<template>
    <div class="loader">
        <div class="wrap">
            <video autoplay loop muted playsinline>
                <source src="@/assets/u-loader.mp4" type="video/mp4">
            </video>
            <!-- <span>Sending...</span> -->
        </div>
    </div>
</template>

<style>
.loader {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, .2);
    display: grid;
    place-items: center;

    video {
        max-width: 80px;
        background-color: rgba(255, 255, 255, .51);
        outline: none;
        border: none;
        /* border: solid 1px rgb(233, 233, 233); */
        border-radius: 8px;
    }
}
</style>
