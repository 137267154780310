<!-- eslint-disable max-len -->
<template>
  <Header goBackVisible />
  <div class="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:py-8 lg:px-8 lg:flex lg:items-center justify-center flex-col">
    <div class="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between max-w-7xl w-full my-6">
      <div>
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Accreditation
        </h3>
        <div class="">
          <div class="text-xs text-gray-400 truncate ">
            <div v-if="form.status === null">
              <span class="text-yellow-500">Pending</span>
            </div>
            <div v-if="form.status === true">
              <span class="text-green-500">Accepted</span>
            </div>
            <div v-if="form.status === false">
              <span class="text-red-500">Rejected</span>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3 flex sm:mt-0 sm:ml-4">
        <button type="button" @click="updateStatus(false)"
          class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Decline
        </button>
        <button type="button" @click="updateStatus(true)"
          class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Accept
        </button>
      </div>
    </div>
    <div class="relative overflow-hidden">
      <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="text-lg max-w-prose mx-auto">
          <p class="mt-8 text-lg text-zinc-600 leading-8">Name: {{ form.first_name }} {{ form.last_name }}</p>
          <p class="text-lg text-zinc-600 leading-8">Country: {{ form.country }}</p>
          <p class="text-lg text-zinc-600 leading-8">Media: {{ form.media_name }}</p>
          <p class="text-lg text-zinc-600 leading-8">Social:
            <a :href="form.social" target="_blank">[link]</a>
          </p>
          <p class="text-lg text-zinc-600 leading-8">Portfolio:
            <a :href="form.portfolio" target="_blank">[link]</a>
          </p>
          <p class="text-lg text-zinc-600 leading-8">About:
            <span class="text-lg text-gray-600">{{ form.about }}</span>
          </p>
        </div>
      </div>
      <div class="mt-8 relative px-4 sm:px-6 lg:px-8">
        <form class="relative">
          <div v-for="(input, index) in inputs" :key="index" class="flex items-center mb-4">
            <label for="name">
              <input name="name" type="text" v-model="input.value"
                class="border border-zinc-300 rounded-md py-2 px-4 mr-4" placeholder="Enter a name" />
            </label>
            <button type="button" @click="deleteInput(index)"
              class="text-gray-500 hover:text-red-500 focus:outline-none">
              <svg class="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
          <div class="flex justify-end">
            <button type="button" @click="addInput" class="px-4 py-2
        border border-gray-300 shadow-sm text-sm
        font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">Add More</button>
          </div>
        </form>
        <button type="button" @click="saveNames"
          class="mt-4 bg-zinc-800 hover:bg-zinc-700 text-white font-semibold py-3 px-6 rounded-md shadow-md transition duration-300 ease-in-out flex items-center justify-center"
          :disabled="isLoading">
          <svg v-if="isLoading" class="animate-spin h-5 w-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none"
            viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
          </svg>
          <svg v-else class="h-5 w-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4"></path>
          </svg>
          <span>Update</span>
        </button>
      </div>
    </div>
  </div>
  <Toast :message="toastMessage" v-if="toastMessage" />
</template>

<script setup>
import supabase from '@/lib/supabaseClient';
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Toast from '@/components/ToastView.vue';
import sendMediaAcceptEmail from '@/lib/sendMediaAcceptEmail';
import sendMediaNotAcceptEmail from '@/lib/sendMediaNotAcceptEmail';
import Header from '@/components/HeaderView.vue';

const form = ref([]);
const route = useRoute();
const router = useRouter();
const formId = route.params.id;
const inputs = ref([{ value: '' }]);
const toastMessage = ref('');
const isLoading = ref(false);

const addInput = () => {
  inputs.value.push({ value: '' });
};

const deleteInput = (index) => {
  inputs.value.splice(index, 1);
};

const toastHide = (message) => {
  toastMessage.value = message;
  setTimeout(() => {
    toastMessage.value = null;
  }, 3000);
};

const getForms = async () => {
  try {
    const { data: formData, error: formError } = await supabase
      .from('forms')
      .select('*')
      .eq('id', formId)
      .single();
    if (formError) {
      console.error('Error getting forms:', formError);
    } else {
      const { data: userData, error: userError } = await supabase
        .from('profiles')
        .select('email')
        .eq('id', formData.user_id);
      if (!userError) {
        if (userData && userData.length > 0) {
          formData.userEmail = userData[0].email;
        }

        form.value = formData;

        inputs.value = formData.names
          ? formData.names.map((name) => ({ value: name }))
          : [];
      } else {
        console.error('Error getting user data:', userError);
      }
    }
  } catch (error) {
    console.error('Error getting forms:', error);
  }
};

const saveNames = async () => {
  isLoading.value = true;
  const validInputs = inputs.value.filter((input) => input.value.trim() !== '');
  const names = validInputs.map((input) => input.value.trim());

  try {
    const { error } = await supabase
      .from('forms')
      .update({ names })
      .eq('id', formId);
    if (error) {
      console.error('Error saving names:', error);
    } else {
      toastHide('Data send successfully');
      router.go(0);
    }
  } catch (error) {
    console.error('Error saving names:', error);
  } finally {
    isLoading.value = false;
  }
};

const updateStatus = async (status) => {
  try {
    const { error } = await supabase
      .from('forms')
      .update({ status })
      .eq('id', formId);

    if (error) {
      console.error('Error updating status:', error);
    } else {
      form.value.status = status;
      const formData = {
        firstName: form.value.first_name,
        lastName: form.value.last_name,
        country: form.value.country,
        mediaName: form.value.media_name,
        names: form.value.names,
        userEmail: form.value.userEmail,
      };
      if (status === true) {
        await sendMediaAcceptEmail(formData);
      }

      if (status === false) {
        await sendMediaNotAcceptEmail(formData);
      }
      toastHide('Status updated successfully');
      router.push({ name: 'dashboard' });
    }
  } catch (error) {
    console.error('Error updating status:', error);
  }
};

onMounted(() => {
  getForms();
});
</script>
