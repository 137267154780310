<template>
  <Header />
  <div class="max-w-7xl
  mx-auto py-24 px-4 sm:px-6 lg:py-8 lg:px-8
  lg:flex lg:items-center justify-center flex-col custom-h">
    <div class="text-lg max-w-prose mx-auto">
      <h1>
        <span class="block text-base text-center
        text-zinc-600 font-semibold tracking-wide
        uppercase">Ultrace
          2024</span>
        <span class="mt-2 block text-3xl text-center leading-8
          font-extrabold tracking-tight text-gray-900
          sm:text-4xl">Media
          Accreditation</span>
      </h1>
    </div>
    <FilterBadges
      :all-count="allCount"
      :pending-count="pendingCount"
      :accepted-count="acceptedCount"
      :rejected-count="rejectedCount"
      @filter-changed="applyFilter" />
    <FormList :forms="filteredForms" />
    <div class="mt-6">
      <a href="#" class="w-full flex justify-center items-center px-4 py-2
        border border-gray-300 shadow-sm text-sm
        font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
        View all
      </a>
    </div>
  </div>
  <div class="footer">
    v0.1.0
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import supabase from '@/lib/supabaseClient';
import FormList from '@/components/FormList.vue';
import Header from '@/components/HeaderView.vue';
import FilterBadges from '@/components/FilterBadges.vue';

const forms = ref([]);
const filteredForms = ref([]);
const allCount = ref(0);
const pendingCount = ref(0);
const acceptedCount = ref(0);
const rejectedCount = ref(0);

const updateCounts = (data) => {
  allCount.value = data.length;
  pendingCount.value = data.filter((form) => form.status === null).length;
  acceptedCount.value = data.filter((form) => form.status === true).length;
  rejectedCount.value = data.filter((form) => form.status === false).length;
};

const getForms = async () => {
  try {
    const { data, error } = await supabase.from('forms').select('*');
    if (!error) {
      forms.value = data;
      filteredForms.value = forms.value.filter((form) => form.status === null);
      updateCounts(data);
    }
  } catch (error) {
    console.error('Error getting forms:', error);
  }
};

const applyFilter = (filter) => {
  switch (filter) {
    case 'all':
      filteredForms.value = forms.value;
      break;
    case 'pending':
      filteredForms.value = forms.value.filter((form) => form.status === null);
      break;
    case 'accepted':
      filteredForms.value = forms.value.filter((form) => form.status === true);
      break;
    case 'rejected':
      filteredForms.value = forms.value.filter((form) => form.status === false);
      break;
    default:
      // Handle any unexpected cases, or throw an error
      console.warn(`Unexpected filter: ${filter}`);
  }
};

onMounted(() => {
  getForms();
});

</script>

<style scss>
.custom-h {
  min-height: calc(100vh - 70px - 21px);
}

.footer {
  text-align: right;
  font-size: 14px;
  padding-right: 14px;
  color: #d1d1d1;
}
</style>
