/* eslint-disable object-curly-newline */

const sendMediaAccreditationEmail = async (formData) => {
  // eslint-disable-next-line operator-linebreak
  const { firstName, lastName, phone, country, mediaName, social, portfolio, about, email } =
    formData;

  const emailBodyHtml = `
    <body style="font-family: Arial, sans-serif; background-color: #f8f8f8;">
      <div style="max-width: 600px; margin: 0 auto; padding: 20px; background-color: #ffffff; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);">
        <div style="text-align: center; margin-bottom: 20px;">
          <h1 style="color: #333333; font-size: 24px; margin-bottom: 10px;">Ultrace 2024</h1>
          <h2 style="color: #666666; font-size: 18px;">Media Accreditation</h2>
        </div>
        <p style="color: #666666; font-size: 16px; line-height: 1.5;">Thank you for your media accreditation application. We have received your submission and will review it as soon as possible.</p>
        <p style="color: #666666; font-size: 16px; line-height: 1.5;">A copy of the information you provided has been sent to the email address you specified. Please review the details below to ensure everything is accurate:</p>
        <ul style="color: #666666; font-size: 16px; line-height: 1.5; padding-left: 20px;">
          <li>Name: ${firstName} ${lastName}</li>
          <li>Phone: ${phone}</li>
          <li>Country: ${country}</li>
          <li>Media: ${mediaName}</li>
          <li>Social: <a href="${social}" style="color: #0066cc;">Link</a></li>
          <li>Portfolio: <a href="${portfolio}" style="color: #0066cc;">Link</a></li>
          <li>About: ${about}</li>
        </ul>
        <p style="color: #666666; font-size: 16px; line-height: 1.5;">If you need to make any changes or have additional questions, please don't hesitate to contact us at info@ultraceofficial.com.</p>
        <p style="color: #666666; font-size: 16px; line-height: 1.5;">We appreciate your patience as we process your application and will notify you once a decision has been made regarding your media accreditation status.</p>
        <p style="color: #666666; font-size: 16px; line-height: 1.5; font-weight: bold;">Status: Under Review</p>
      </div>
    </body>
`;

  const params = {
    to: email,
    from: 'media@ultraceofficial.com',
    subject: 'Ultrace 2024 - Media Accrediation',
    bodyHtml: emailBodyHtml,
  };

  try {
    const response = await fetch(
      'https://7v0ecd76i2.execute-api.eu-central-1.amazonaws.com/default/SesSenderVue3',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
        mode: 'cors',
      },
    );

    if (response.ok) {
      console.log('Email sent successfully');
    } else {
      console.error('Error sending email');
    }
  } catch (err) {
    console.error('Error sending email:', err);
  }
};

export default sendMediaAccreditationEmail;
