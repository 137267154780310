/* eslint-disable object-curly-newline */

const sendMediaAcceptEmail = async (formData) => {
  const { firstName, lastName, mediaName, country, names, userEmail } = formData;

  const emailBodyHtml = `
    <body style="font-family: Arial, sans-serif; background-color: #f8f8f8;">
      <div style="max-width: 600px; margin: 0 auto; padding: 20px; background-color: #ffffff; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);">
        <div style="text-align: center; margin-bottom: 20px;">
          <h1 style="color: #333333; font-size: 24px; margin-bottom: 10px;">Ultrace 2024</h1>
          <h2 style="color: #666666; font-size: 18px;">Media Accreditation</h2>
        </div>
        <p style="color: #666666; font-size: 16px; line-height: 1.5;">We are pleased to inform you that your media accreditation application for the Ultrace Official event has been approved. Congratulations! You can now proceed with the necessary arrangements to cover the event as a credentialed member of the media. </p>
        <p style="color: #666666; font-size: 16px; line-height: 1.5;">Your accreditation details are as follows:</p>
        <ul style="color: #666666; font-size: 16px; line-height: 1.5; padding-left: 20px;">
          <li>Name: ${firstName} ${lastName}</li>
          <li>Media: ${mediaName} </li>
          <li>Country: ${country}</li><br/>

          Listed as:
          ${names.map((name) => `<li>${name} </li>`).join('')}
        </ul>
        <p style="color: #666666; font-size: 16px; line-height: 1.5;">Please note that your media accreditation grants you access to designated press areas and certain event proceedings. However, it is essential to follow all guidelines and instructions provided by the event organizers to ensure a smooth and successful experience for everyone involved.</p>
        <p style="color: #666666; font-size: 16px; line-height: 1.5;">Here are some key bits of information to keep your
                        experience as a media representative streamlined:<br/>
                        Friday the 28th is set up day. Only exhibitors, drivers, media, and various set up crews are
                        allowed on the stadium grounds. You can collect your media passes and wristband from a kiosk
                        labelled "MEDIA" by the main entrance from Królewiecka Street. Saturday and Sunday are busy
                        since that's when we open to the general public and it is the core of the entire event.</p>
        <p style="color: #666666; font-size: 16px; line-height: 1.5;">We look forward to your coverage of the Ultrace Official event and hope you will capture the essence of this remarkable occasion through your lens.</p>
        <p style="color: #666666; font-size: 16px; line-height: 1.5; font-weight: bold;">If you have any further questions or require additional information, please don't hesitate to contact us at media@ultraceofficial.com.</p>
        <p style="color: #666666; font-size: 16px; line-height: 1.5; font-weight: bold;">Status: Approved</p>
        <p style="color: #666666; font-size: 16px; line-height: 1.5;">Best regards,<br/>Łukasz Dawczyk</p>
      </div>
    </body>
`;

  const params = {
    to: userEmail,
    from: 'media@ultraceofficial.com',
    subject: 'Ultrace 2024 - Media Accrediation',
    bodyHtml: emailBodyHtml,
  };

  try {
    const response = await fetch(
      'https://7v0ecd76i2.execute-api.eu-central-1.amazonaws.com/default/SesSenderVue3',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
        mode: 'cors',
      },
    );

    if (response.ok) {
      console.log('Email sent successfully');
    } else {
      console.error('Error sending email');
    }
  } catch (err) {
    console.error('Error sending email:', err);
  }
};

export default sendMediaAcceptEmail;
