<!-- eslint-disable vuejs-access{{ me }}ity/label-has-for -->
<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<!-- eslint-disable max-len -->
<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="wrapp">
    <div class="container mx-auto max-w-screen-lg">
      <div class="space-y-6">
        <template v-if="!registrationClosed">
          <div class="bg-white px-4 py-5 sm:rounded-lg sm:p-6">
            <div class="md:grid md:grid-cols-3 md:gap-6">
              <div class="md:col-span-1">
                <h3 class="text-lg font-medium leading-6 text-gray-900">Information</h3>
                <p class="mt-1 text-sm text-gray-500">
                  Submit your personal details for media accreditation.
                </p>
              </div>
              <div class="mt-5 space-y-6 md:col-span-2 md:mt-0">
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 sm:col-span-3">
                    <TextField v-model="firstNameModel" :component-data="firstName"
                      @update:valid="firstNameValid = $event" ref="firstNameInput" />
                  </div>
                  <div class="col-span-6 sm:col-span-3">
                    <TextField v-model="lastNameModel" :component-data="lastName" @update:valid="lastNameValid = $event"
                      ref="lastNameInput" />
                  </div>
                </div>
                <div class="relative col-span-6 sm:col-span-3">
                  <CountryForm @input-change="handleInputChange" @update:valid="countryValid = $event"
                    ref="countryInput" />
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <PhoneForm :myProp="selected" @update:valid="phoneValid = $event"
                    @update:phoneValue="phoneNumberValue = $event" ref="phoneInput" />
                </div>
              </div>
              <div class="md:col-span-1">
                <h3 class="text-lg font-medium leading-6 text-gray-900">Social Media and Portfolio.</h3>
                <p class="mt-1 text-sm text-gray-500">
                  Provide your social media handles and portfolio links to showcase your work and online presence.
                </p>
              </div>
              <div class="mt-5 space-y-6 md:col-span-2 md:mt-0">
                <div class="col-span-6 sm:col-span-3">
                  <TextField v-model="nameModel" :component-data="nameName" @update:valid="nameValid = $event"
                    ref="nameInput" />
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <TextField v-model="socialModel" :component-data="socialName" @update:valid="socialValid = $event"
                    ref="socialInput" />
                </div>
                <div class="col-span-6 sm:col-span-3">
                  <TextField v-model="websiteModel" :component-data="websiteName" @update:valid="websiteValid = $event"
                    ref="websiteInput" />
                </div>
                <div>
                  <div class="mt-1">
                    <TextArea v-model="textareaModel" :component-data="textareaName"
                      @update:valid="textareaValid = $event" ref="textareaInput" />
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-end mt-5">
              <button @click="submit" :disabled="loading" type="submit"
                class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-zinc-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-zinc-700 focus:outline-none focus:ring-2 focus:ring-zinc-500 focus:ring-offset-2">
                submit
              </button>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="bg-white px-4 py-5 sm:rounded-lg sm:p-6">
            <p class="mt-1 text-sm w-full text-gray-500 text-center">
              Registration is currently closed.
            </p>
          </div>
        </template>
      </div>
    </div>
  </div>

  <Toast :message="toastMessage" v-if="toastMessage" />
</template>

<style scoped>
.wrapp {
  @apply min-h-screen p-6 flex items-center bg-white;
}

button.cursor-not-allowed {
  background-color: red;
  cursor: not-allowed;
}

.text-field {
  @apply mt-1
  focus:ring-zinc-500
  focus:border-zinc-500
  block
  w-full
  shadow-sm
  sm:text-sm
  border-gray-300
  rounded-md
}

</style>

<script setup>
// import { PhotoIcon, UserCircleIcon } from '@heroicons/vue/24/solid'
import TextField from '@/components/TextField.vue';
import CountryForm from '@/components/CountryForm.vue';
import PhoneForm from '@/components/PhoneForm.vue';
import TextArea from '@/components/TextArea.vue';
import supabase from '@/lib/supabaseClient';
import Toast from '@/components/ToastView.vue';
import { ref, computed, inject } from 'vue';
import { useRouter } from 'vue-router';
import sendMediaAccreditationEmail from '@/lib/sendMediaAccreditationEmail';

const ses = inject('session');
const registrationClosed = ref(true);

const loading = ref(false);

const router = useRouter();

const firstNameValid = ref(false);
const lastNameValid = ref(false);
const socialValid = ref(false);
const nameValid = ref(false);
const countryValid = ref(false);
const websiteValid = ref(false);
const textareaValid = ref(false);
const phoneValid = ref(false);
const firstNameInput = ref(null);
const lastNameInput = ref(null);
const countryInput = ref(null);
const phoneInput = ref(null);
const socialInput = ref(null);
const nameInput = ref(null);
const websiteInput = ref(null);
const textareaInput = ref(null);
const phoneNumberValue = ref('');
const toastMessage = ref('');

const firstNameModel = ref('');
const firstName = ref({
  idForm: 'firstName',
  label: 'First Name',
  placeholder: 'Tyler',
  pattern: '',
  minLenght: 2,
  maxLenght: 30,
});

const lastNameModel = ref('');
const lastName = ref({
  idForm: 'lastName',
  label: 'Last Name',
  placeholder: 'Durden',
  pattern: '',
  minLenght: 2,
  maxLenght: 30,
});

const nameModel = ref('');
const nameName = ref({
  idForm: 'name',
  label: 'Your media name',
  placeholder: 'e.g Tyler Photography',
  pattern: '',
  minLenght: 2,
  maxLenght: 30,
});

const socialModel = ref('');
const socialName = ref({
  idForm: 'social',
  label: 'Link to your Social Media e.g Instagram',
  placeholder: 'e.g https://instagram.com/ultrace_official',
  patternError: 'Invalid url',
  pattern: '^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$',
  minLenght: 2,
  maxLenght: 256,
});

const websiteModel = ref('');
const websiteName = ref({
  idForm: 'website',
  label: 'Link to your Portfolio (website, google drive) ',
  patternError: 'Invalid url',
  placeholder: 'e.g https://ultraceofficial.com',
  pattern: '^(https?:\\/\\/)?([a-zA-Z0-9.-]+\\.[a-z]{2,})(\\/[^\\s]*)?$',
  minLenght: 2,
  maxLenght: 256,
});

const textareaModel = ref('');
const textareaName = ref({
  idForm: 'textarea',
  label: 'About you',
  patternError: '',
  placeholder: 'Write a few sentences about you',
  pattern: '',
  minLenght: 2,
  maxLenght: 3000,
});

const selected = ref({});
const country = ref('');

const prefix = ref('');

const handleInputChange = (inputValue) => {
  selected.value = inputValue;
  country.value = inputValue.name;
  prefix.value = inputValue.phone;
};

const isFormValid = computed(
  () => firstNameValid.value
    && lastNameValid.value
    && socialValid.value
    && websiteValid.value
    && textareaValid.value
    && phoneValid.value
    && countryValid.value,
);

const toastHide = (message) => {
  toastMessage.value = message;
  setTimeout(() => {
    toastMessage.value = null;
  }, 1300);
};

const submit = async () => {
  firstNameInput.value.blur();
  lastNameInput.value.blur();
  countryInput.value.blur();
  phoneInput.value.blur();
  nameInput.value.blur();
  socialInput.value.blur();
  websiteInput.value.blur();
  textareaInput.value.blur();

  const phoneValue = prefix.value + phoneNumberValue.value;

  if (isFormValid.value) {
    try {
      loading.value = true; // Disable button immediately
      const { error } = await supabase
        .from('forms')
        .insert([
          {
            first_name: firstNameModel.value,
            last_name: lastNameModel.value,
            country: country.value,
            media_name: nameModel.value,
            phone: phoneValue,
            social: socialModel.value,
            portfolio: websiteModel.value,
            names: [`${firstNameModel.value} ${lastNameModel.value}`],
            about: textareaModel.value,
          },
        ]);

      if (!error) {
        toastHide('Data send successfully');
        const formData = {
          firstName: firstNameModel.value,
          lastName: lastNameModel.value,
          phone: phoneValue,
          country: country.value,
          mediaName: nameModel.value,
          social: socialModel.value,
          portfolio: websiteModel.value,
          about: textareaModel.value,
          email: ses.value.user.email,
        };

        await sendMediaAccreditationEmail(formData);
        setTimeout(() => {
          router.go(0);
        }, 1300);
      }
    } catch (err) {
      console.error('Error:', err);
      loading.value = false; // Re-enable button on error
    }
  }
};

</script>
