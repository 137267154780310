import { createRouter, createWebHistory } from 'vue-router';
import DashboardView from '@/views/DashboardView.vue';
import HomeView from '../views/HomeView.vue';
import FormView from '../views/FormView.vue';
import SingleFormView from '../views/SingleFormView.vue';
import SandBox from '../views/SandBox.vue';
import NotAuthorizedView from '../views/NotAuthorizedView.vue';
import AuthView from '../views/AuthView.vue';
import supabase from '../lib/supabaseClient';
import AcceptedMediaList from '../views/AcceptedMediaList.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    meta: { requiresRole: 2 },
  },
  {
    path: '/list',
    name: 'list',
    component: AcceptedMediaList,
    meta: { requiresRole: 2 },
  },
  {
    path: '/formularz',
    name: 'formularz',
    component: FormView,
    meta: { requiresAuth: true },
  },
  {
    path: '/form/:id',
    name: 'FormView',
    component: SingleFormView,
    meta: { requiresRole: 2 },
  },
  {
    path: '/sandbox',
    name: 'sandbox',
    component: SandBox,
  },
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: NotAuthorizedView,
  },
  {
    path: '/auth',
    name: 'auth',
    component: AuthView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

async function requiresAuthentication(to, from, next) {
  const localUser = await supabase.auth.getSession();
  if (!localUser.data.session) {
    next({ name: 'auth' }); // Redirect if not authenticated
  } else {
    next();
  }
}

async function getUserRoles(userId) {
  const { data, error } = await supabase
    .from('user_roles')
    .select('role_id')
    .eq('user_id', userId)
    .single();

  if (error) {
    return [];
  }
  return data.role_id;
}

async function requiresRoles(to, from, next) {
  const localUser = await supabase.auth.getSession();
  if (!localUser.data.session) {
    next({ name: 'auth' });
  } else {
    const userId = localUser.data.session.user.id;
    const userRoles = await getUserRoles(userId);

    if (userRoles === to.meta.requiresRole) {
      next();
    } else {
      next({ name: 'not-authorized' });
    }
  }
}

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    await requiresAuthentication(to, from, next);
  } else if (to.matched.some((record) => record.meta.requiresRole)) {
    await requiresRoles(to, from, next);
  } else {
    next();
  }
});

export default router;
