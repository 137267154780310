<template>
  <router-view/>
</template>

<script setup>
import { provide } from 'vue';
import useSession from '@/composables/useSession';

const { session } = useSession();

provide('session', session);

</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family:
  system-ui,
  -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  Roboto,
  Oxygen,
  Ubuntu,
  Cantarell,
  'Open Sans',
  'Helvetica Neue',
  sans-serif;
  color: #2c3e50;
}

</style>
