<template>
    <Header />
    <div class="max-w-7xl
    min-w-full
  mx-auto py-24 px-4 sm:px-6 lg:py-8 lg:px-8
  lg:flex lg:items-center justify-center flex-col custom-h">
        <div class="text-lg max-w-prose mx-auto">
            <h2 class="text-xl font-bold mb-4">Accepted Media</h2>
            <ul class="-my-5 divide-y divide-gray-200">
                <li v-for="{ name, mediaName } in sortedData" :key="name" class="py-4">
                    <div class="flex items-center space-x-4">
                        <div class="flex-1 min-w-0">
                            <p class="text-sm font-medium text-gray-900 truncate">
                                {{ name }}
                            </p>
                            <p class="text-sm text-gray-500 truncate">
                                {{ mediaName }}
                            </p>
                        </div>
                    </div>
                </li>
            </ul>
            <button class="mt-20 px-4 py-2
            bg-blue-500 text-white
            rounded hover:bg-blue-600" @click="exportToCSV">
                Export to CSV
            </button>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import supabase from '@/lib/supabaseClient';

const data = ref([]);

const fetchAcceptedMedia = async () => {
  try {
    const { data: fetchedData, error } = await supabase
      .from('forms')
      .select('names, media_name')
      .eq('status', 1);

    if (!error) {
      data.value = fetchedData.flatMap(
        (form) => form.names.map((name) => (
          { name, mediaName: form.media_name })),
      );
    } else {
      console.error('Error fetching accepted media:', error);
    }
  } catch (error) {
    console.error('Error fetching accepted media:', error);
  }
};

const sortedData = computed(() => [...data.value].sort((a, b) => a.name.localeCompare(b.name)));

const exportToCSV = () => {
  const header = 'Name,Media Name\n';
  const csvData = header + sortedData.value
    .map(({ name, mediaName }) => `${name},${mediaName}`)
    .join('\n');
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
  const link = document.createElement('a');
  const filename = 'accepted_media.csv';

  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    window.open(blob, '_blank');
  }
};

onMounted(() => {
  fetchAcceptedMedia();
});
</script>
