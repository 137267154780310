<template>
  <label :for="props.componentData.idForm" class="label">
    {{ props.componentData.label }}
    <textarea
      :name="props.componentData.idForm"
      :id="props.componentData.idForm"
      :value="props.modelValue"
      :placeholder="props.componentData.placeholder"
      class="text-field"
      :class="{ 'border-red-500': localError, 'border-gray-300': !localError }"
      ref="input"
      @input="$emit('update:modelValue', $event.target.value)" @blur="validate"
      rows="10" />
  </label>
  <span class="field-error" v-if="props.componentData.errorMessage || localError">
    {{ props.componentData.errorMessage || localError }}
  </span>
</template>

<script setup>
import { defineProps, ref, watch } from 'vue';

const emit = defineEmits(['update:modelValue', 'update:valid']);
const localError = ref(null);

const input = ref(null);

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  componentData: {
    type: Object,
    default: () => ({
      idForm: 'idForm',
      label: 'Label',
      type: 'text',
      placeholder: '',
      errorMessage: null,
      patternError: '',
      pattern: '',
      minLenght: null,
      maxLenght: null,
    }),
  },

});

const validate = (event) => {
  if (props.componentData.pattern
    && !new RegExp(props.componentData.pattern).test(event.target.value)) {
    if (props.componentData.patternError) {
      localError.value = props.componentData.patternError;
    } else {
      localError.value = 'Invalid pattern';
    }
    return;
  }

  if (props.componentData.minLenght
      && event.target.value.length <= (props.componentData.minLenght - 1)) {
    localError.value = `Minimum ${props.componentData.minLenght} characters`;
    return;
  }

  if (props.componentData.maxLenght
      && event.target.value.length >= (props.componentData.maxLenght + 1)) {
    localError.value = `Maximum ${props.componentData.maxLenght} characters`;
    return;
  }

  localError.value = null;

  emit('update:valid', !localError.value);
};

watch(localError, () => {
  emit('update:valid', !localError.value);
});

const blur = () => {
  validate({ target: { value: input.value.value } });
};

defineExpose({
  blur,
});

</script>

<style scoped>
.text-field {
  @apply
  mt-1
  focus:ring-zinc-500
  focus:border-zinc-500
  block
  w-full
  shadow-sm
  sm:text-sm
  placeholder-gray-300
  rounded-md
}

.label {
   @apply block text-sm font-medium text-gray-700;
}

.field-error {
  @apply flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1
}
</style>
