<template>
    <header>
        <div class="wraper">
            <div v-if="props.goBackVisible">
                <button
                @click="goBack"
                type="button"
                class="inline-flex items-center px-4 py-2 border border-gray-300
      shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white
       hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    <svg class="-ml-1 mr-2 h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                        fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M9.707 16.707a1 1 0
            01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0
          011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0
          010 1.414z" clip-rule="evenodd" />
                    </svg>
                    Go Back
                </button>
            </div>
        </div>
        <div class="wraper inline-flex justify-end">
            <button @click="signOut"
            type="button"
            class="inline-flex items-center px-4 py-2 border border-gray-300
      shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white
      hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Sign Out
            </button>
        </div>
    </header>
</template>

<script setup>
import { useRouter } from 'vue-router';
import supabase from '@/lib/supabaseClient';

const props = defineProps({
  goBackVisible: {
    type: Boolean,
    default: false,
  },
});

const router = useRouter();
const signOut = async () => {
  await supabase.auth.signOut();
  router.push('/auth');
};

const goBack = () => {
  router.go(-1);
};

</script>

<style>
header {
    padding: 16px 32px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
</style>
