<template>
  <label for="country" class="block text-sm font-medium text-gray-700">
    Country / Region
    <input
      v-model="selectedCountry"
      @input="filterCountries"
      @blur="closeDropdown"
      @keydown.up="highlightPrevCountry"
      @keydown.down="highlightNextCountry"
      @keydown.enter="selectHighlightedCountry"
      type="text"
      name="country"
      id="country"
      placeholder="Type your country"
      class="text-input"
      :class="{ 'border-red-500': localError, 'border-gray-300': !localError }"
      />
  </label>
  <ul v-if="filteredCountries.length" class="list-wrap">
    <li v-for="(country, index) in filteredCountries" :key="country.code"
      :class="{ 'bg-slate-50': highlightedIndex === index }" @click="selectCountry(country)"
      @keydown.enter="selectCountry(country)" class="cursor-pointer px-3 py-1 hover:bg-slate-50">
      {{ country.flag }} {{ country.name }}
    </li>
  </ul>
  <span class="field-error" v-if="localError">
    {{ localError }}
  </span>
</template>

<script setup>
import { ref, defineEmits } from 'vue';
import { countries, getEmojiFlag } from 'countries-list';

const emit = defineEmits(['input-change']);
const localError = ref(null);
const selectedCountry = ref('');
const filteredCountries = ref([]);
const highlightedIndex = ref(-1);

const getCountriesData = Object.entries(countries).map(([code, country]) => ({
  code,
  name: country.name,
  flag: getEmojiFlag(code),
  phone: country.phone[0],
}));
const getCountries = getCountriesData.sort((a, b) => a.name.localeCompare(b.name));

const validateCountry = () => {
  const isValidCountry = getCountries.some(
    (country) => country.name.toLowerCase() === selectedCountry.value.toLowerCase(),
  );

  if (!isValidCountry) {
    localError.value = 'Please enter a valid country';
  } else {
    localError.value = null;
  }

  emit('update:valid', !localError.value);
};

const blur = () => {
  validateCountry();
};

defineExpose({
  blur,
});

const selectCountry = (country) => {
  selectedCountry.value = country.name;
  filteredCountries.value = [];
  highlightedIndex.value = -1;
  emit('input-change', country);
  validateCountry();
  emit('update:valid', !localError.value);
  // selectPrefix(country);
};

const filterCountries = () => {
  filteredCountries.value = getCountries.filter(
    (country) => country.name.toLowerCase().includes(selectedCountry.value.toLowerCase()),
  );

  highlightedIndex.value = -1;
};

const closeDropdown = () => {
  setTimeout(() => {
    filteredCountries.value = [];
    highlightedIndex.value = -1;
    validateCountry();
  }, 400);
};

const highlightPrevCountry = () => {
  if (highlightedIndex.value > 0) {
    highlightedIndex.value -= 1;
  }
};

const highlightNextCountry = () => {
  if (highlightedIndex.value < filteredCountries.value.length - 1) {
    highlightedIndex.value += 1;
  }
};

const selectHighlightedCountry = () => {
  if (highlightedIndex.value >= 0) {
    const pointedCountry = filteredCountries.value[highlightedIndex.value];
    selectCountry(pointedCountry);
  }
};

</script>

<style scope>
  .text-input {
    @apply
    rounded-md
    mt-1
    block
    w-full
    shadow-sm
    focus:border-zinc-500
    focus:ring-zinc-500
    sm:text-sm
    placeholder-gray-300
  }

  .list-wrap {
    @apply
    absolute
    z-10
    m-0
    mt-1
    block
    max-h-48
    w-full
    overflow-hidden
    overflow-y-auto
    rounded-md
    border
    border-gray-300
    bg-white
    shadow-lg
    focus:border-zinc-500
    focus:ring-zinc-500
    sm:text-sm
  }

    .field-error {
      @apply flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1
    }
</style>
