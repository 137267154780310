/* eslint-disable object-curly-newline */

const sendMediaNotAcceptEmail = async (formData) => {
  const { userEmail } = formData;
  const emailBodyHtml = `
    <body style="font-family: Arial, sans-serif; background-color: #f8f8f8;">
      <div style="max-width: 600px; margin: 0 auto; padding: 20px; background-color: #ffffff; border-radius: 5px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);">
        <div style="text-align: center; margin-bottom: 20px;">
          <h1 style="color: #333333; font-size: 24px; margin-bottom: 10px;">Ultrace 2024</h1>
          <h2 style="color: #666666; font-size: 18px;">Media Accreditation</h2>
        </div>
        <p style="color: #666666; font-size: 16px; line-height: 1.5;">Thank you for your recent media accreditation application for the Ultrace Official event. After careful review, we regret to inform you that your application has not been approved at this time. </p>
        <p style="color: #666666; font-size: 16px; line-height: 1.5;">While we appreciate your interest in covering the event, we have received an overwhelming number of applications from highly qualified members of the media. Unfortunately, we have a limited number of accreditations available, and we have had to make difficult decisions based on specific criteria and requirements. </p>
        <p style="color: #666666; font-size: 16px; line-height: 1.5;">We understand that this news may be disappointing, but we want to assure you that the decision was not made lightly. We carefully evaluated each application and had to prioritize those that best aligned with our event's objectives and needs. </p>
        <p style="color: #666666; font-size: 16px; line-height: 1.5;">We encourage you to apply for media accreditation for future events, as the selection process is conducted independently for each occasion.</p>
        <p style="color: #666666; font-size: 16px; line-height: 1.5; font-weight: bold;">Thank you for your understanding, and we wish you the best in your future endeavors. </p>
        <p style="color: #666666; font-size: 16px; line-height: 1.5; font-weight: bold;">Sincerely,<br/>Club de Ultrace </p>
      </div>
    </body>
`;

  const params = {
    to: userEmail,
    from: 'media@ultraceofficial.com',
    subject: 'Ultrace 2024 - Media Accrediation',
    bodyHtml: emailBodyHtml,
  };

  try {
    const response = await fetch(
      'https://7v0ecd76i2.execute-api.eu-central-1.amazonaws.com/default/SesSenderVue3',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
        mode: 'cors',
      },
    );

    if (response.ok) {
      console.log('Email sent successfully');
    } else {
      console.error('Error sending email');
    }
  } catch (err) {
    console.error('Error sending email:', err);
  }
};

export default sendMediaNotAcceptEmail;
