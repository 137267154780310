<template>
    <div class="flow-root mt-6 w-full max-w-xl">
        <ul class="-my-5 divide-y divide-gray-200">
            <li v-for="form in forms" :key="form.id" class="py-4">
                <div class="flex items-center space-x-4">
                    <div class="flex-1 min-w-0">
                        <p class="text-sm font-medium text-gray-900 truncate">
                            {{ form.first_name }} {{ form.last_name }}
                        </p>
                        <p class="text-sm text-gray-500 truncate">
                            {{ form.media_name }}
                        </p>
                    </div>
                    <div class="flex-1 min-w-0 flex">
                        <div class="flex-1 min-w-0 mr-2 text-right">
                            <p class="text-sm text-gray-500 truncate">
                                {{ form.country }}
                            </p>
                            <div class="text-xs text-gray-400 truncate ">
                                <div v-if="form.status === null">
                                    <span class="text-yellow-500">Pending</span>
                                </div>
                                <div v-if="form.status === true">
                                    <span class="text-green-500">Accepted</span>
                                </div>
                                <div v-if="form.status === false">
                                    <span class="text-red-500">Rejected</span>
                                </div>
                            </div>
                        </div>
                        <a href="#" @click.prevent="viewForm(form.id)"
                            class="inline-flex
                            items-center shadow-sm px-2.5 py-0.5
                            border border-gray-300 text-sm leading-5 font-medium
                            rounded-full text-gray-700 bg-white hover:bg-gray-50">
                            View
                        </a>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
  props: {
    forms: {
      type: Array,
      required: true,
    },
  },
  methods: {
    viewForm(formId) {
      // Redirect to the form view with the provided formId
      this.$router.push(`/form/${formId}`);
    },
  },
};
</script>
