<script setup>
import { onMounted, ref, inject } from 'vue';
import supabase from '@/lib/supabaseClient';

import DefaultLayout from '@/layouts/DefaultLayout.vue';
import StatusView from '@/views/StatusView.vue';
import FormView from '@/views/FormView.vue';

import Loader from '@/components/LoaderView.vue';

const formData = ref();
const loading = ref(true);
const ses = inject('session');

const handleHasForm = async () => {
  try {
    const { data, error } = await supabase
      .from('forms')
      .select()
      .eq('user_id', ses.value.user.id)
      .limit(1);

    if (error) {
      console.error('Error getting forms:', error);
    } else {
      loading.value = false;
      const [firstForm] = data;
      if (firstForm) {
        formData.value = firstForm;
      }
    }
  } catch (error) {
    console.error('Error getting forms:', error);
  }
};

onMounted(() => {
  setTimeout(handleHasForm, 3000);
});

</script>

<template>
  <DefaultLayout>
    <div v-if="loading">
      <Loader />
    </div>
    <div v-else>
      <StatusView v-if="formData" :data="formData" />
      <FormView v-else />
    </div>
  </DefaultLayout>
</template>
