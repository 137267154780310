import { ref } from 'vue';
import supabase from '@/lib/supabaseClient';

export default function useSession() {
  const session = ref();

  supabase.auth.getSession().then(({ data }) => {
    session.value = data.session;
  });

  supabase.auth.onAuthStateChange((_, _session) => {
    session.value = _session;
  });
  return { session };
}
