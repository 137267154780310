<!-- eslint-disable max-len -->
<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <label for="phone_number" class="block text-sm font-medium text-gray-700">
    Phone Number
    <div class="relative mt-1 flex">
      <div class="flag-wrapper" @click="showInput = !showInput" @keboard.enter="showInput = !showInput"
        style="height: calc(100% - 4px); margin-top: 2px;">
        <span v-if="selectedFlag">{{ selectedFlag }}</span>
        <span v-else>&nbsp;&nbsp;&nbsp;</span>&nbsp;
        <Arrowsvg />
      </div>
      <input type="text" name="phone_number" id="phone_number" class="text-field pl-16" placeholder="555 0134"
        @blur="validate" ref="input" @input="$emit('update:phoneValue', $event.target.value)"
        :class="{ 'border-red-500': localError, 'border-gray-300': !localError }" />
    </div>
    <input v-model="selectedPrefix" @input="filterPrefixes" @blur="closeDropdownPrefix"
      @keydown.up="highlightPrevPrefix" @keydown.down="highlightNextPrefix" @keydown.enter="selectHighlightedPrefix"
      placeholder="Type your country" v-if="showInput" type="text" class="mt-1 text-field" />
  </label>
  <ul v-if="filteredPrefixes.length"
    class="absolute z-10 m-0 mt-1 block max-h-48 w-full overflow-hidden overflow-y-auto rounded-md border border-gray-300 bg-white shadow-lg focus:border-zinc-500 focus:ring-zinc-500 sm:text-sm">
    <li v-for="(country, index) in filteredPrefixes" :key="country.code"
      :class="{ 'bg-slate-50': highlightedIndexPrefix === index }" @click="selectPrefix(country)"
      @keydown.enter="selectPrefix(country)" class="cursor-pointer px-3 py-1 hover:bg-slate-50">
      {{ country.flag }} {{ country.name }} +{{ country.phone }}
    </li>
  </ul>
  <span class="field-error" v-if="localError">
    {{ localError }}
  </span>
</template>

<script setup>
import { ref, watch, defineEmits } from 'vue';
import { countries, getEmojiFlag } from 'countries-list';
import Arrowsvg from '@/assets/arrowView.vue';

const props = defineProps({
  myProp: [Object, String],
});

const highlightedIndexPrefix = ref(-1);
const highlightedIndex = ref(-1);
const localError = ref(null);
const input = ref(null);

const getCountriesData = Object.entries(countries).map(([code, country]) => ({
  code,
  name: country.name,
  flag: getEmojiFlag(code),
  phone: country.phone[0],
}));
const getCountries = getCountriesData.sort((a, b) => a.name.localeCompare(b.name));

const showInput = ref(false);
const selectedFlag = ref('');

const selectedPrefix = ref('');
const filteredPrefixes = ref([]);

const filterPrefixes = () => {
  selectedPrefix.value = selectedPrefix.value.replace(/^\+/, '');

  filteredPrefixes.value = getCountries.filter(
    (country) => country.name.toLowerCase().includes(selectedPrefix.value.toLowerCase())
|| country.phone.toString().includes(selectedPrefix.value.toLowerCase()),
  );
  highlightedIndex.value = -1;
};

const closeDropdownPrefix = () => {
  setTimeout(() => {
    filteredPrefixes.value = [];
    highlightedIndex.value = -1;
  }, 400);

  // check if the selectedPrefix is a valid phone number
  const selectedCountry = getCountries.find(
    (country) => country.phone.toString() === selectedPrefix.value,

  );
  if (selectedCountry) {
    selectedPrefix.value = selectedCountry.phone;
    selectedFlag.value = selectedCountry.flag;
  }
};

const highlightPrevPrefix = () => {
  if (highlightedIndex.value > 0) {
    highlightedIndex.value -= 1;
  }
};

const highlightNextPrefix = () => {
  if (highlightedIndex.value < filteredPrefixes.value.length - 1) {
    highlightedIndex.value += 1;
  }
};

const selectPrefix = (prefix) => {
  selectedPrefix.value = prefix.phone;
  selectedFlag.value = prefix.flag;
  showInput.value = false;
  filteredPrefixes.value = [];
  highlightedIndex.value = -1;
};

const emit = defineEmits(['validation', 'update:valid', 'update:phoneValue']);

watch(
  () => props.myProp,
  (newValue) => {
    selectPrefix(newValue);
  },
);

watch(
  () => input.value?.value,
  (newValue) => {
    emit('update:phoneValue', newValue);
  },
);

const selectHighlightedPrefix = () => {
  if (highlightedIndex.value >= 0) {
    const pointedCountry = filteredPrefixes.value[highlightedIndex.value];
    selectPrefix(pointedCountry);
  }
};

const validate = (event) => {
  const inputValue = event.target.value;
  const minLength = 4; // Minimum length for phone numbers
  const maxLength = 15; // Maximum length for phone numbers

  // Regular expression pattern to match phone numbers
  const phoneNumberRegex = /^[\d+]+$/;

  if (
    phoneNumberRegex.test(inputValue)
    && inputValue.length >= minLength
    && inputValue.length <= maxLength
  ) {
    localError.value = null; // Clear the error message if the input is valid
    emit('update:valid', true);
  } else {
    localError.value = 'Invalid phone number'; // Set the error message if the input is invalid
    emit('update:valid', false);
  }
};

const blur = () => {
  validate({ target: { value: input.value.value } });
};

defineExpose({
  blur,
});

</script>

<style scoped>
.flag-wrapper {
  @apply absolute flex h-full cursor-pointer
  items-center justify-between rounded-md
  px-3 text-gray-500
}

.text-field {
  @apply block w-full rounded-md
  focus:border-zinc-500
  placeholder-gray-300
  focus:ring-zinc-500 sm:text-sm
}

.field-error {
  @apply flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1
}
</style>
