<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<!-- eslint-disable max-len -->
<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<!-- eslint-disable max-len -->
<!-- eslint-disable vuejs-accessibility/label-has-for -->
<!-- eslint-disable no-plusplus -->
<template>
<div class="wrap">
<div>Selected: || {{ selectedPrefix }}</div>
 <div
      class="flex items-center justify-between border border-gray-300 rounded-md px-4 py-2 cursor-pointer"
      @click="showInput = !showInput"
      :class="{ 'ring-2 ring-indigo-500': showInput }"
    >
      <span v-if="selectedFlag">{{ selectedFlag }}</span>
      <span v-else>Select Phone Prefix</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </div>
<input
v-model="selectedPrefix"
@input="filterPrefixes"
@blur="closeDropdown"
@keydown.up="highlightPrevPrefix"
@keydown.down="highlightNextPrefix"
@keydown.enter="selectHighlightedPrefix"
v-if="showInput"
type="text"
class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
<ul
      v-if="filteredPrefixes.length"
      class="relative z-10 bg-white shadow-lg m-0 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border overflow-hidden max-h-48 overflow-y-auto">
        <li
        v-for="(country, index) in filteredPrefixes"
        :key="country.code"
        :class="{ 'bg-slate-50': highlightedIndex === index }"
        @click="selectPrefix(country)"
        @keydown.enter="selectPrefix(country)"
        class="py-1 px-3 hover:bg-slate-50 cursor-pointer">
        {{ country.flag }} {{ country.name }} +{{ country.phone }}

      </li>
      </ul>
</div>

</template>

<script setup>
// import countries from '@/lib/db.json';
import { countries, getEmojiFlag } from 'countries-list';
import { ref } from 'vue';

const getCountries = Object.entries(countries).map(([code, country]) => ({
  code,
  name: country.name,
  flag: getEmojiFlag(code),
  phone: country.phone[0],
}));

const selectedPrefix = ref('');
const selectedFlag = ref('');
const filteredPrefixes = ref([]);
const highlightedIndex = ref(-1);
const showInput = ref(false);
// const selectedPrefix = ref(null);

const filterPrefixes = () => {
  selectedPrefix.value = selectedPrefix.value.replace(/^\+/, '');

  filteredPrefixes.value = getCountries.filter(
    (country) => country.name.toLowerCase().includes(selectedPrefix.value.toLowerCase())
      || country.phone.toString().includes(selectedPrefix.value.toLowerCase()),
  );
  highlightedIndex.value = -1;
};

const closeDropdown = () => {
  setTimeout(() => {
    filteredPrefixes.value = [];
    highlightedIndex.value = -1;
  }, 400);
};

const highlightPrevPrefix = () => {
  if (highlightedIndex.value > 0) {
    highlightedIndex.value -= 1;
  }
};

const highlightNextPrefix = () => {
  if (highlightedIndex.value < filteredPrefixes.value.length - 1) {
    highlightedIndex.value += 1;
  }
};

const selectPrefix = (prefix) => {
  selectedPrefix.value = prefix.phone;
  selectedFlag.value = prefix.flag;
  showInput.value = false;
  filteredPrefixes.value = [];
  highlightedIndex.value = -1;
};

const selectHighlightedPrefix = () => {
  if (highlightedIndex.value >= 0) {
    const pointedCountry = filteredPrefixes.value[highlightedIndex.value];
    selectPrefix(pointedCountry);
  }
};

</script>

<style>
.wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    margin: 0 auto;
    max-width: 53ch;
}

</style>
